import { createContext, ReactNode, useState } from "react";
import { AppContextValue, InitFileDto } from "./app.types";
import { UUID } from "gx-npm-lib";

const AppContext = createContext<AppContextValue>({} as AppContextValue);
const AppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [productName, setProductName] = useState<string>("");
  const [imageLoc, setImageLoc] = useState<string>("");
  const [surveyInstructions, setSurveyInstructions] = useState<string>("");
  const [questionsCompleted, setQuestionsCompleted] = useState<number>(0);
  const [questionsTotal, setQuestionsTotal] = useState<number>(0);
  const [docsUploaded, setDocsUploaded] = useState<number>(0);
  const [docsTotal, setDocsTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [initProductId, setInitProductId] = useState<UUID>("");
  const [initId, setInitId] = useState<UUID>("");
  const [isOnboardingDrawerOpen, setIsOnboardingDrawerOpen] = useState(false);
  const [surveyRequestedByCompanyName, setSurveyRequestedByCompanyName] = useState("");
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [files, setFiles] = useState<InitFileDto[]>([]);

  const contextValue = {
    docsTotal,
    docsUploaded,
    files,
    imageLoc,
    isLoading,
    productName,
    questionsCompleted,
    questionsTotal,
    surveyInstructions,
    isSnackbarOpen,
    initProductId,
    initId,
    isOnboardingDrawerOpen,
    surveyRequestedByCompanyName,
    isPreviewMode,
    setFiles,
    setIsPreviewMode,
    setDocsTotal,
    setDocsUploaded,
    setImageLoc,
    setIsLoading,
    setProductName,
    setQuestionsCompleted,
    setQuestionsTotal,
    setSurveyInstructions,
    setIsSnackbarOpen,
    setInitProductId,
    setInitId,
    setIsOnboardingDrawerOpen,
    setSurveyRequestedByCompanyName,
  };

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
