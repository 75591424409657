import React, { Fragment, useContext, useEffect } from "react";
import surveyWorkspaceOverviewStyles from "./app.styles";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ProductUnderConsideration from "./ui/product-under-consideration/product-under-consideration.component";
import SurveyProgressInformation from "./ui/survey-progress-information/survey-progress-information.component";
import { GartnerFooter, Loader } from "gx-npm-ui";
import { defaultToEmptyString, getAsyncRequest, useCaptureEventsV2 } from "gx-npm-lib";
import { AppContext } from "./app.context";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import { useParams, useSearchParams } from "react-router-dom";
import { SurveyPreviewData } from "./app.types";
import LegalBannerComponent from "./ui/legal-banner/legal-banner.component";
import { ClientEvent } from "./app.constants";

const useStyles = makeStyles(surveyWorkspaceOverviewStyles);
const LOCAL_STORE_PREVIEW_KEY = "survey_setup";
const PREVIEW_PRODUCT_NAME = "ABC Company";

const AppPreview = ({ isSetupMode }: { isSetupMode: boolean }) => {
  const classes = useStyles();

  const {
    setIsLoading,
    setProductName,
    setSurveyInstructions,
    setQuestionsCompleted,
    setQuestionsTotal,
    setDocsTotal,
    isLoading,
    setIsSnackbarOpen,
    setInitId,
    setSurveyRequestedByCompanyName,
    setIsPreviewMode,
    setFiles,
  } = useContext(AppContext);

  const { initiativeId } = useParams();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    setIsPreviewMode(true);
  }, [setIsPreviewMode]);

  useEffect(() => {
    // Preview launched from inside setup

    if (!isSetupMode) {
      return;
    }
    const initialQuestionnaireDataForOverview = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);

    const updateContextWithLocalStore = (storageData: string) => {
      const parsedData: SurveyPreviewData = JSON.parse(storageData);
      setSurveyInstructions(parsedData.instructions);
      setSurveyRequestedByCompanyName(parsedData.companyName);
      setDocsTotal(parsedData.requestedDocuments?.length);
      setQuestionsTotal(parsedData.requirementsCount);
      setProductName(PREVIEW_PRODUCT_NAME);
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORE_PREVIEW_KEY) {
        const questionnaireData = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);
        if (questionnaireData) {
          updateContextWithLocalStore(questionnaireData);
        }
      }
    };

    if (initialQuestionnaireDataForOverview) {
      updateContextWithLocalStore(initialQuestionnaireDataForOverview);
    }

    setIsLoading(false);
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [
    isSetupMode,
    setDocsTotal,
    setIsLoading,
    setProductName,
    setQuestionsTotal,
    setSurveyInstructions,
    setSurveyRequestedByCompanyName,
  ]);

  useEffect(() => {
    // Preview launched from outside setup

    if (isSetupMode) {
      return;
    }

    (async () => {
      setIsLoading(true);
      const url = `api/v2/initiatives/${initiativeId}/survey-preview/overview`;
      const response = await getAsyncRequest(url);
      if (response?.status !== 200 || !response?.data?.data || typeof response?.data?.data !== "object") {
        setIsSnackbarOpen(true);
        return;
      }
      const {
        productName,
        instructions,
        questionsCompleted,
        questionsTotal,
        docsTotal,
        requestedByCompanyName,
        surveyInstructionFiles,
      } = response.data.data;
      setProductName(productName);
      setSurveyInstructions(instructions);
      setQuestionsCompleted(questionsCompleted);
      setQuestionsTotal(questionsTotal);
      setDocsTotal(docsTotal);
      setSurveyRequestedByCompanyName(requestedByCompanyName);
      setFiles(surveyInstructionFiles);
      setIsLoading(false);
    })();
  }, [
    initiativeId,
    isSetupMode,
    setDocsTotal,
    setIsLoading,
    setIsSnackbarOpen,
    setProductName,
    setQuestionsCompleted,
    setQuestionsTotal,
    setSurveyInstructions,
    setFiles,
    setSurveyRequestedByCompanyName,
  ]);

  useEffect(() => {
    setInitId(defaultToEmptyString(initiativeId));
  }, [initiativeId, setInitId]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    captureEvents([
      {
        eventType: ClientEvent.SURVEY_PREVIEW_OVERVIEW_PAGE_VIEWED,
        metaData: { initiativeId, gxtmType, gxtmSource },
      },
    ]);
  }, [captureEvents, initiativeId, searchParams]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classNames(classes.container)}>
          <div className={classNames(classes.overviewRoot, classes.overviewRootForPreview)}>
            <div className={classNames(classes.cardAndSidebarContainer)}>
              <div className={classNames(classes.scorecardContainer)}>
                <ProductUnderConsideration />
              </div>
              <div className={classNames(classes.sidebarContainer)}>
                <SurveyProgressInformation />
              </div>
            </div>
          </div>
          <LegalBannerComponent />
          <GartnerFooter />
        </div>
      )}
      <Snackbar />
    </Fragment>
  );
};

export default AppPreview;
