import React from "react";
import { IconProps } from "../types";

const xmlnsUrl = "http://www.w3.org/2000/svg";

export const DownloadIcon = ({
  fillPath = "#676565",
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M12.6197 5.5979L12.6197 14.2102L15.3567 11.5609C15.4762 11.4455 15.6209 11.3877 15.7908 11.3877C15.9608 11.3877 16.1055 11.4455 16.2249 11.5609C16.3442 11.6765 16.4025 11.8178 16.3999 11.9848C16.3972 12.152 16.3388 12.2908 16.2249 12.4012L12.5231 15.984C12.3741 16.128 12.2004 16.2 12.0019 16.2C11.8034 16.2 11.6297 16.128 11.4807 15.984L7.77894 12.4012C7.65965 12.2856 7.6 12.1455 7.6 11.9811C7.6 11.8166 7.65965 11.6765 7.77894 11.5609C7.89838 11.4455 8.04437 11.389 8.21693 11.3915C8.38963 11.3942 8.53302 11.4507 8.64709 11.5609L11.3842 14.2102L11.3842 5.5979C11.3842 5.42823 11.4433 5.28619 11.5617 5.17179C11.6799 5.05727 11.8266 5 12.0019 5C12.1772 5 12.324 5.05727 12.4422 5.17179C12.5605 5.28619 12.6197 5.42823 12.6197 5.5979Z"
        fill={fillPath}
      />
      <path
        d="M6.6 17.8C6.26863 17.8 6 18.0686 6 18.4C6 18.7314 6.26863 19 6.6 19H17.4C17.7314 19 18 18.7314 18 18.4C18 18.0686 17.7314 17.8 17.4 17.8H6.6Z"
        fill={fillPath}
      />
    </svg>
  );
};
