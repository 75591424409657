import React, { Fragment, useContext, useEffect } from "react";
import surveyWorkspaceOverviewStyles from "./app.styles";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ProductUnderConsideration from "./ui/product-under-consideration/product-under-consideration.component";
import SurveyProgressInformation from "./ui/survey-progress-information/survey-progress-information.component";
import { GartnerFooter, Loader } from "gx-npm-ui";
import { defaultToEmptyString, getAsyncRequest, isValidResponse, useCaptureEventsV2 } from "gx-npm-lib";
import { AppContext } from "./app.context";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import { useParams, useSearchParams } from "react-router-dom";
import LegalBannerComponent from "./ui/legal-banner/legal-banner.component";
import { ClientEvent } from "./app.constants";

const useStyles = makeStyles(surveyWorkspaceOverviewStyles);

const App = () => {
  const classes = useStyles();

  const {
    setIsLoading,
    setProductName,
    setImageLoc,
    setSurveyInstructions,
    setQuestionsCompleted,
    setQuestionsTotal,
    setDocsTotal,
    setDocsUploaded,
    isLoading,
    setIsSnackbarOpen,
    setInitProductId,
    setInitId,
    setSurveyRequestedByCompanyName,
    setFiles,
  } = useContext(AppContext);

  const { initiativeId: initId, initProductId: initProdId } = useParams();
  const [searchParams] = useSearchParams();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    const initiativeId = defaultToEmptyString(initId);
    const initProductId = defaultToEmptyString(initProdId);

    setInitId(initiativeId);
    setInitProductId(initProductId);

    (async () => {
      setIsLoading(true);
      const response = await getAsyncRequest(
        `api/v2/initiatives/${initiativeId}/survey-recipients/${initProductId}/overview`
      );
      if (!isValidResponse(response)) {
        setIsSnackbarOpen(true);
        return;
      }
      const {
        productName,
        imageLoc,
        instructions,
        questionsCompleted,
        questionsTotal,
        docsUploaded,
        docsTotal,
        requestedByCompanyName,
        surveyInstructionFiles,
      } = response.data.data;
      setProductName(productName);
      setImageLoc(imageLoc);
      setSurveyInstructions(instructions);
      setQuestionsCompleted(questionsCompleted);
      setQuestionsTotal(questionsTotal);
      setDocsUploaded(docsUploaded);
      setDocsTotal(docsTotal);
      setSurveyRequestedByCompanyName(requestedByCompanyName);
      setIsLoading(false);
      setFiles(surveyInstructionFiles || []);
    })();
  }, [
    setProductName,
    setImageLoc,
    setSurveyInstructions,
    setQuestionsTotal,
    setQuestionsCompleted,
    setDocsTotal,
    setDocsUploaded,
    setFiles,
    setSurveyRequestedByCompanyName,
    setIsLoading,
    setInitId,
    setInitProductId,
    setIsSnackbarOpen,
    initId,
    initProdId,
  ]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    const gxtmSource = searchParams.get("gxtm_source") || "";
    const gxtmType = searchParams.get("gxtm_type") || "";
    captureEvents([
      {
        eventType: ClientEvent.SURVEY_OVERVIEW_PAGE_VIEWED,
        metaData: { initiativeId: initId, initProductId: initProdId, gxtmType, gxtmSource },
      },
    ]);
  }, [captureEvents, initId, initProdId, searchParams]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classNames(classes.container)}>
          <div className={classNames(classes.overviewRoot)}>
            <div className={classes.cardAndSidebarContainer}>
              <div className={classes.scorecardContainer}>
                <ProductUnderConsideration />
              </div>
              <div className={classNames(classes.sidebarContainer)}>
                <SurveyProgressInformation />
              </div>
            </div>
          </div>
          <LegalBannerComponent />
          <GartnerFooter />
        </div>
      )}
      <Snackbar />
    </Fragment>
  );
};

export default App;
