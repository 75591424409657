// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZQfDkB9T0eHj2wF21_E{display:flex;margin-left:16px}.AZQfDkB9T0eHj2wF21_E svg{height:16px;width:16px}`, "",{"version":3,"sources":["webpack://./src/ui/file-extension-icon-display/file-extension-icon-display.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,0BACE,WAAA,CACA,UAAA","sourcesContent":[".root {\n  display: flex;\n  margin-left: 16px;\n  svg {\n    height: 16px;\n    width: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AZQfDkB9T0eHj2wF21_E`
};
export default ___CSS_LOADER_EXPORT___;
