import { makeStyles } from "@material-ui/core/styles";
import { DownloadIcon } from "gx-npm-icons";
import { getAsyncRequest, useCaptureEventsV2, UUID } from "gx-npm-lib";
import { ProductLogo, SnackbarBanner, TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../app.constants";
import { AppContext } from "../../app.context";
import PreviewProductLogoSVG from "../../assets/images/preview-product-logo.svg";
import { GCOM_4101__docManagementV2 } from "../../lib/feature-flag";
import FileExtensionIconDisplayComponent from "../file-extension-icon-display/file-extension-icon-display.component";
import styles from "./product-under-consideration.styles";

const useStyles = makeStyles(styles);
const ProductUnderConsideration = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const { files, imageLoc, isPreviewMode, productName, surveyInstructions, surveyRequestedByCompanyName } =
    useContext(AppContext);
  const { initiativeId = "", initProductId = "" } = useParams<{ initiativeId: UUID; initProductId: UUID }>();
  const captureEvents = useCaptureEventsV2();
  const ffGCOM4104 = useFeatureFlag(GCOM_4101__docManagementV2);

  const handleClickDownload = async (fileId: UUID) => {
    const url = `/api/v2/initiatives/${initiativeId}/survey-recipients/${initProductId}/instruction/file/${fileId}/download`;
    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_self");
      if (isPreviewMode) {
        return;
      }
      const metaData = { initiativeId, initProductId, fileId };
      captureEvents([{ eventType: ClientEvent.SURVEY_OVERVIEW_DOWNLOAD_INSTRUCTIONS_FILE, metaData }]);
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <div className={classes.root}>
      <TypographyComponent boldness="regular" color="iron" styling="p4">
        {t("Product under consideration")}
      </TypographyComponent>
      <div className={classes.logoPtagWrapper}>
        <ProductLogo logo={isPreviewMode ? PreviewProductLogoSVG : imageLoc} imageWidth="32px" imageHeight="32px" />
        <TypographyComponent boldness="medium" color="carbon" rootClassName="gx-product-name" styling="p2">
          {productName}
        </TypographyComponent>
      </div>
      <TypographyComponent boldness="regular" color="iron" styling="p4">
        {t("Questionnaire Instructions")}
      </TypographyComponent>
      <TypographyComponent boldness="regular" color="coal" rootClassName="gx-survey-instructions" styling="p3">
        {surveyInstructions}
      </TypographyComponent>
      {ffGCOM4104 && files.length > 0 && (
        <div className={classes.fileContainer}>
          <TypographyComponent boldness="regular" color="iron" rootClassName={classes.filesHeading} styling="p4">
            {t("Document provided by ")}
            {surveyRequestedByCompanyName}
          </TypographyComponent>
          {files.map((file) => {
            const fileParts = file.fileName.split(".");
            const fileExt = fileParts[fileParts.length - 1].toLocaleLowerCase();
            return (
              <div
                key={file.fileId}
                className={classes.filePill}
                onClick={() => handleClickDownload(file.fileId)}
                onKeyDown={(e) => e.key === "Enter" && handleClickDownload(file.fileId)}
                role="button"
                tabIndex={0}
                aria-label={"Download attached instructions file"}
              >
                <TooltipV2
                  PopperProps={{ modifiers: { offset: { offset: "0px, 16px" } } }}
                  placement="top"
                  title={t("Download document")}
                >
                  <div className={classes.filePillContent}>
                    <FileExtensionIconDisplayComponent fileExt={fileExt} />

                    <TypographyComponent
                      boldness="regular"
                      color="carbon"
                      rootClassName={classes.filePillName}
                      styling="p3"
                    >
                      {file.fileName}
                    </TypographyComponent>

                    <DownloadIcon />
                  </div>
                </TooltipV2>
              </div>
            );
          })}
        </div>
      )}
      <SnackbarBanner isOpen={hasError} setIsOpen={setHasError} type="ERROR" isDefaultErrorMessage={true} />
    </div>
  );
};

export default ProductUnderConsideration;
