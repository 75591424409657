import { colorPalette } from "gx-npm-common-styles";

const productUnderConsiderationStyles = {
  root: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: "8px",
    minHeight: 260,
    padding: 32,
    "& .gx-product-name": {
      paddingLeft: 16,
    },
    "& .gx-survey-instructions": {
      marginTop: 8,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
  },
  logoPtagWrapper: {
    alignItems: "center",
    display: "flex",
    marginBottom: 40,
    marginTop: 12,
  },
  fileContainer: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "40px",
    "&:hover": {
      backgroundColor: `var(--${colorPalette.interactions.hoverCta})`,
    },
  },
  filesHeading: {
    paddingBottom: "16px",
  },
  filePill: {
    alignItems: "center",
    border: `1px solid ${colorPalette.neutrals.silver.hex}`,
    borderRadius: "24px",
    cursor: "pointer",
    display: "flex",
    height: "32px",
    marginBottom: "12px",
    width: "fit-content",
    "&:hover, &:focus": {
      backgroundColor: colorPalette.interactions.lightBerry.hex,
      border: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
      outline: "none",
    },
  },
  filePillName: {
    marginLeft: "8px",
    marginRight: "8px",
  },

  filePillContent: {
    display: "flex",
    alignItems: "center",
  },
};

export default productUnderConsiderationStyles;
